import request from '@/api/models/request';
import {API_METHODS} from '@/const';

class SiteTracker {
  #baseUrl = 'https://logc.site24x7.com';
  #url = '/event/receiver';
  #CAN_SEND = Boolean(process.env.VUE_APP_SITE24X7_API_KEY);
  #CHANNEL_NAME = process.env.VUE_APP_CHANNEL_CONFIG_NAME;
  #REQUEST_ERROR_LOG_TOKEN = process.env.VUE_APP_SITE24X7_REQUEST_ERROR_TOKEN;

  // 'REQUEST ERRORS' log for site24x7;
  sendRequestError(err) {
    console.log(' #REQUEST_ERROR_LOG_TOKEN', this.#REQUEST_ERROR_LOG_TOKEN);
    if (
      !this.#CAN_SEND ||
      !this.#REQUEST_ERROR_LOG_TOKEN ||
      err.config?.baseURL === this.#baseUrl
    ) return;
    request({
      url: `${this.#url}?token=${this.#REQUEST_ERROR_LOG_TOKEN}`,
      baseUrl: this.#baseUrl,
      method: API_METHODS.POST,
      needPrepareParams: false,
      config: {
        headers: {
          'Content-Type': 'application/json',
        },
      },
      params: {
        date: (new Date()).toISOString(),
        status: err.response.status,
        service: 'request',
        channel: this.#CHANNEL_NAME,
        message: err.response?.data?.message || err.response?.statusText,
        request_data: JSON.stringify(Object.fromEntries(err.config?.data?.entries?.() || [])),
        url: err.request?.responseURL,
      },
    }).catch(console.log);
  }
};

const siteTracker = new SiteTracker();
export default siteTracker;
