import request from './request';
import {validate} from '@/api/requestValidation';
import {FAVORITE_API, FAVORITE_EVENTS_API, FAVORITES_API} from '@/urls';
import {API_METHODS} from '@/const';

export default {
  get_favorites(params) {
    return request({
      url: FAVORITES_API,
      method: API_METHODS.GET,
      params,
    });
  },
  delete_favorite({id} = {}) {
    return validate(
        {id},
        () => request({
          url: `${FAVORITE_API}/${id}`,
          method: API_METHODS.DELETE,
        }),
    );
  },
  add_favorite({
    id,
    type,
  } = {}) {
    const params = {
      favoritable_id: id,
      favoritable_type: type,
    };

    return validate(
        params,
        () => request({
          url: FAVORITE_API,
          method: API_METHODS.POST,
          params,
        }),
    );
  },
  get_favorite_events({
    stream_status,
    offset,
    limit,
    start_date,
    end_date,
    sort_field,
    sort_direction,
  }) {
    const params = Object.fromEntries(
        Object.entries({
          stream_status,
          start_date,
          end_date,
          offset,
          limit,
          sort_field,
          sort_direction,
        }).filter(([_, v]) => v));

    return request({
      url: FAVORITE_EVENTS_API,
      method: API_METHODS.GET,
      params,
    });
  },
};
